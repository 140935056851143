.btn, .btn-history {
	-webkit-transition: all .4s ease
}

.btn, .btn-add, .btn-add .fa, .btn-load, .btn-shop, .btn-spicy {
	vertical-align: top
}

.page-banner .breadcrumbs li.active a, .tel {
	pointer-events: none
}

.boxed-v #wrapper, body.boxed-v #wrapper {
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, .3);
	margin: 0 auto
}

.beans-fadeslider, .beans-slider, .beans-stepslider, .contact-form, .page-banner, .parallax-section, .port-parallex {
	width: 100%;
	overflow: hidden;
	position: relative
}

.beans-fadeslider .slick-dots, .beans-slider .slick-dots, .beans-stepslider .slick-dots {
	list-style: none;
	margin: 0;
	padding: 0
}

.btn-history:before, .page-banner .heading:before, .page-heading .heading10:after, .page-heading .heading2:after, .page-heading .heading2:before, .page-heading .heading3:after, .page-heading .heading3:before, .page-heading .heading5:after, .page-heading .heading5:before, .page-heading .heading6:after, .page-heading .heading7:after, .page-heading .heading7:before, .page-heading .heading8:after, .page-heading .heading8:before, .page-heading .heading9:after, .page-heading .heading:after {
	content: "";
	position: absolute
}

.cart-form:after, .contact-form2:after, .login-form2:after, .update-form:after {
	content: " ";
	display: block;
	clear: both
}

.btn-add, .btn-add .fa, .btn-load, .btn-shop, .btn-spicy, .portfolio-view span, .portfolio-view:after, .portfolio-view:before {
	display: inline-block
}

.beans-fadeslider .slick-dots, .beans-fadeslider .slick-list, .beans-fadeslider .slick-slider, .beans-fadeslider .slick-track, .beans-fadeslider .slide, .error-section:after, .parallax-holder, .parallax-holder .parallax-frame, .parallax-section .over, .stretch {
	position: absolute;
	width: 100%
}

.stretch img {
	width: 100%
}

button, input[type=button], input[type=reset], input[type=file], input[type=submit] {
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	cursor: pointer
}

input[type=password], input[type=search], input[type=text], input[type=tel], input[type=email], input[type=url], textarea {
	-webkit-appearance: none;
	-webkit-border-radius: 0
}

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
	-webkit-appearance: none
}

span.icomoon[class*=" icon-"], span.icomoon[class^=icon-] {
	font-family: icomoon;
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.icon-hammer:before {
	content: "\e601"
}

.icon-lightning:before {
	content: "\e602"
}

.icon-triangular:before {
	content: "\e603"
}

.icon-truck:before {
	content: "\e604"
}

.icon-svg1:before {
	content: "\e600"
}

.icon-anatomy:before {
	content: "\eaeb"
}

.icon-arm:before {
	content: "\eaec"
}

.icon-gym:before {
	content: "\eaed"
}

.icon-sport:before {
	content: "\eaee"
}

span.fontello[class*=" icon-"]:before, span.fontello[class^=icon-]:before {
	font-family: fontello;
	font-style: normal;
	font-weight: 400;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	margin-left: .2em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

#style-changer .changer-opener:hover, #style-changer .list-color a:hover, #style-changer .list-style .btn2:hover, #style-changer .list-style .btn:hover, .btn-add:hover, .btn-shop:hover, .btn:hover, .page-banner .breadcrumbs li a:hover, .portfolio-view:hover, .quantity-form .decrease:hover, .screen-reader-text:focus, .wp-pagenavi a, .wp-pagenavi span {
	text-decoration: none
}

.btn, .btn-dark, .btn-f-info, .btn-more {
	text-transform: uppercase
}

.icon-molar3:before {
	content: "\e800"
}

.icon-molar2:before {
	content: "\e801"
}

.icon-molar1:before {
	content: "\e802"
}

.icon-molar:before {
	content: "\e803"
}

.icon-premolar:before {
	content: "\e804"
}

.icon-implants:before {
	content: "\e805"
}

.icon-teeth-cleaning:before {
	content: "\e806"
}

.icon-wiping-swipe-for-floors:before {
	content: "\e807"
}

.icon-clean:before {
	content: "\e808"
}

.icon-dish:before {
	content: "\e809"
}

.icon-vacuum:before {
	content: "\e80a"
}

.btn {
	border-radius: 0;
	padding: 8px 20px;
	text-align: center;
	white-space: normal;
	letter-spacing: 2px;
	font-size: 12px;
	line-height: 20px;
	transition: all .4s ease
}

.btn.focus, .btn:focus, .btn:hover {
	color: inherit
}

.btn-dark {
	border-color: #2a2a2a;
	color: #2a2a2a !important
}

.dark .btn-dark {
	border-color: rgba(143, 143, 143, .6);
	color: rgba(143, 143, 143, .6) !important
}

.btn-dark:hover {
	background: #2a2a2a;
	border-color: #2a2a2a;
	color: #fff !important
}

.dark .btn-dark:hover {
	background: 0 0 !important;
	color: #8f8f8f !important;
	border-color: #8f8f8f !important
}

.btn-f-default {
	color: #fff;
	background: 0 0;
	border: 1px solid #fff
}

.btn-f-default:hover {
	color: #fff
}

.btn-f-info {
	color: #fff;
	border: 1px solid transparent
}

.btn-f-info:hover {
	color: #fff;
	border-color: #fff;
	background: 0 0
}

.btn-history {
	border: 0;
	color: #262626;
	font-size: 16px;
	overflow: hidden;
	border-radius: 0;
	background: #fff;
	font-style: italic;
	position: relative;
	padding: 11px 58px 11px 19px;
	transition: all .4s ease
}

.btn-history:before {
	top: 0;
	width: 58px;
	bottom: -1px;
	right: -20px;
	transform: skewX(-20deg);
	-ms-transform: skewX(-20deg);
	-webkit-transform: skewX(-20deg);
	-webkit-transition: all .4s ease;
	transition: all .4s ease
}

.btn-history:after {
	top: 10px;
	bottom: 0;
	z-index: 2;
	right: 14px;
	color: #fff;
	font-size: 20px;
	content: "\f101";
	position: absolute;
	transform: skewX(0);
	font-family: FontAwesome;
	-ms-transform: skewX(0);
	-webkit-transform: skewX(0);
	-webkit-transition: all .4s ease;
	transition: all .4s ease
}

.btn-load, .know-more {
	-webkit-transition: all .4s ease
}

.btn-history:hover {
	color: #fff
}

.btn-history:hover:before {
	background: #222
}

.dark .btn-history:hover:before {
	background: #191919
}

.btn-more {
	color: #8f8f8f;
	border: 1px solid #ddd
}

.dark .btn-more {
	color: rgba(143, 143, 143, .6);
	border-color: rgba(143, 143, 143, .6)
}

.btn-more:hover {
	color: #fff
}

.dark .btn-more:hover {
	background: 0 0;
	border-color: #8f8f8f;
	color: #8f8f8f
}

.btn-resume, .btn-spicy, .know-more {
	color: #fff;
	text-transform: uppercase
}

.btn-resume:hover {
	background: 0 0;
	border-color: #222
}

.btn-spicy {
	background: #c0392b;
	padding: 3px 12px;
	margin: 2px 0 0;
	font-size: 11px;
	line-height14px;
}

.btn-add, .portfolio-view {
	font-size: 12px;
	line-height: 14px
}

.btn-spicy.new {
	background: #e67e22
}

.btn-spicy.seller {
	background: #2c3e50
}

.know-more {
	border: 1px solid transparent;
	transition: all .4s ease
}

.dark .know-more {
	background: 0 0;
	color: rgba(143, 143, 143, .6);
	border-color: rgba(143, 143, 143, .6)
}

.know-more:hover {
	color: #fff;
	background: #222;
	border-color: #222
}

.dark .know-more:hover {
	background: 0 0;
	border-color: #8f8f8f;
	color: #8f8f8f
}

.btn-add {
	color: #2a2a2a;
	padding: 0 2px;
	margin: 0 13px 0 0;
	text-transform: uppercase;
	position: relative
}

.btn-add:after, .btn-add:before {
	position: absolute;
	top: 0;
	bottom: auto
}

.btn-add:before {
	left: 100%;
	right: auto;
	content: "]"
}

.btn-add:after {
	left: auto;
	right: 100%;
	content: "["
}

.dark .btn-add {
	color: #fff
}

.btn-add .fa {
	margin: 1px 3px 0 0
}

.btn-load {
	color: #2a2a2a;
	border: 1px solid #2a2a2a;
	transition: all .4s ease
}

.dark .btn-load {
	color: rgba(143, 143, 143, .6);
	border-color: rgba(143, 143, 143, .6)
}

.btn-load:hover {
	color: #fff;
	background: #2a2a2a
}

.dark .btn-load:hover {
	background: 0 0;
	color: #8f8f8f;
	border-color: #8f8f8f
}

.btn-shop, .btn-shop.add:hover {
	color: #fff
}

.btn-shop {
	position: relative;
	text-transform: uppercase
}

.btn-shop .fa {
	position: absolute;
	left: 100%;
	top: 1px;
	margin: 0 0 0 9px
}

.portfolio-view {
	color: #8f8f8f;
	-webkit-transition: all .4s ease;
	transition: all .4s ease
}

.portfolio-view span {
	padding: 0 1px;
	letter-spacing: 2px;
	-webkit-transition: all .4s ease;
	transition: all .4s ease;
	vertical-align: middle
}

.portfolio-view:hover span {
	padding: 0 8px
}

.portfolio-view:hover:after, .portfolio-view:hover:before {
	color: #8f8f8f
}

.portfolio-view:after {
	content: "]";
	vertical-align: middle
}

.portfolio-view:before {
	content: "[";
	vertical-align: middle
}

.page-heading {
	z-index: 5;
	font-size: 16px;
	overflow: hidden;
	max-width: 900px;
	line-height: 26px;
	position: relative;
	text-align: center;
	margin: 0 auto 60px
}

.page-heading .fa-star {
	color: #222;
	margin: -20px 4px 0;
	vertical-align: middle
}

.page-heading h1 {
	margin: 0;
	font: 50px/60px Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif
}

.page-heading h2 {
	margin: 0;
	font: 36px/50px Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif
}

.page-heading h3 {
	margin: 0;
	font: 35px/45px Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif
}

.page-heading h4 {
	margin: 0;
	line-height: 40px;
	font: 30px/40px Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif
}

.page-heading h5 {
	margin: 0;
	line-height: 30px;
	font: 25px/30px Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif
}

.page-heading h6 {
	margin: 0;
	line-height: 25px;
	font: 20px/25px Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif
}

.page-heading .title {
	color: #8f8f8f;
	font-size: 14px;
	line-height: 25px
}

.page-heading .small-heading {
	font-size: 26px;
	line-height: 30px
}

.page-heading p {
	margin: 0 !important
}

.page-heading .font-light {
	font-weight: 300
}

.page-heading .font-bold {
	font-weight: 700
}

.page-heading .font-medium {
	font-weight: 600
}

.page-heading .heading {
	display: block;
	padding: 0 0 25px;
	position: relative
}

.page-heading .heading:after {
	bottom: 0;
	left: 50%;
	margin: 0 0 0 -40px;
	width: 80px;
	height: 4px
}

.page-heading .heading2 {
	display: block;
	padding: 0 0 20px;
	position: relative
}

.page-heading .heading2:after, .page-heading .heading2:before {
	left: 50%;
	bottom: 1px;
	margin: 0 0 0 -29px;
	width: 23px;
	height: 2px
}

.page-heading .heading2:before {
	margin: 0 0 0 4px
}

.page-heading .heading3 {
	display: block;
	padding: 0 0 20px;
	position: relative
}

.page-heading .heading4, .page-heading .heading5, .page-heading .heading6, .page-heading .heading7, .page-heading .heading8 {
	display: inline-block;
	vertical-align: top
}

.page-heading .heading3:after {
	left: 50%;
	bottom: 1px;
	margin: 0 0 0 -40px;
	width: 80px;
	height: 2px
}

.page-heading .heading3:before {
	left: 50%;
	bottom: -6px;
	margin: 0 0 0 -4px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 7px 4px 0;
	border-color: transparent
}

.page-heading .heading4 {
	position: relative
}

.page-heading .heading4:before {
	position: absolute;
	top: 50%;
	bottom: auto;
	left: auto;
	right: -63px
}

.page-heading .heading4:after {
	position: absolute;
	top: 50%;
	bottom: auto;
	left: -63px;
	right: auto;
	transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	-ms-transform: rotateY(180deg);
	-o-transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg)
}

.page-heading .heading4:after, .page-heading .heading4:before {
	font-size: 15px;
	content: "\e600";
	line-height: 20px;
	margin: -10px 0 0;
	font-family: icomoon
}

.page-heading .heading5 {
	position: relative;
	z-index: 1
}

.page-heading .heading5:after {
	top: 50%;
	z-index: -1;
	bottom: auto;
	left: 100%;
	right: auto;
	background: 0 0
}

.dark .page-heading .heading5 {
	color: #fff
}

.page-heading .heading5:after, .page-heading .heading5:before {
	margin: -3.5px 0 0 4px;
	width: 7px;
	height: 6px
}

.page-heading .heading5:before {
	top: 50%;
	z-index: -1;
	bottom: auto;
	left: auto;
	right: 100%;
	background: 0 0;
	margin: -3.5px 4px 0 0
}

.page-heading .heading6 {
	padding: 0 0 20px;
	position: relative
}

.page-heading .heading6:after {
	left: 0;
	right: 0;
	bottom: 0;
	height: 4px;
	background: #fff
}

.page-heading .heading7 {
	position: relative
}

.page-heading .heading7:after, .page-heading .heading7:before {
	top: 50%;
	left: -45px;
	margin: -2px 0 0;
	width: 35px;
	height: 6px;
	border: solid transparent;
	border-width: 2px 0
}

.page-heading .heading7:before {
	left: auto;
	right: -45px
}

.page-heading .heading8 {
	position: relative;
	z-index: 1
}

.page-heading .heading8:after {
	top: 50%;
	z-index: -1;
	bottom: auto;
	left: auto;
	right: 100%;
	background: 0 0
}

.page-heading .heading8:after, .page-heading .heading8:before {
	width: 20px;
	height: 2px;
	margin: -1px 26px 0 0
}

.page-heading .heading8:before {
	top: 50%;
	z-index: -1;
	bottom: auto;
	left: 100%;
	right: auto;
	background: 0 0;
	margin: -1px 0 0 26px
}

.page-heading .heading9 {
	display: block;
	padding: 0 0 25px;
	position: relative
}

.page-heading .heading9:after {
	left: 0;
	bottom: 0;
	width: 60px;
	height: 2px
}

.page-heading .heading10 {
	display: block;
	padding: 0 0 25px;
	position: relative
}

.page-heading .heading10:after {
	left: 0;
	bottom: 0;
	width: 35px;
	height: 6px;
	border: solid transparent;
	border-width: 2px 0
}

.page-heading .heading11 {
	color: #222;
	font: 70px/90px "Great Vibes", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.page-heading .white {
	color: #fff
}

.page-heading .lime {
	color: #222
}

.dark .page-heading .lime, .page-heading.white {
	color: #fff
}

.page-heading .icon {
	color: #fff;
	line-height: 2;
	display: block;
	font-size: 37px;
	padding: 3px 0 0;
	text-align: center;
	border-radius: 50%;
	margin: 0 auto 20px;
	width: 80px;
	height: 80px;
	-webkit-transition: all .4s ease;
	transition: all .4s ease
}

.page-banner .breadcrumbs li:first-child:before, .page-banner .mejs-offscreen, .page-banner.small .heading:before {
	display: none
}

.page-heading .icon .fa-fire {
	margin: 7px 0 0
}

.page-heading.left-align {
	text-align: left
}

.page-heading.small .heading:after {
	margin: 0 0 0 -20px;
	width: 40px;
	height: 4px
}

.page-heading.style2 .heading:after {
	margin: 0 0 0 -33px;
	width: 62px;
	height: 2px
}

.page-heading.dark-style .heading:after {
	background: #222
}

.dark .page-heading.dark-style .heading:after {
	background: #fff
}

.page-heading.dark-style .heading2:after, .page-heading.dark-style .heading2:before, .page-heading.dark-style .heading8:after, .page-heading.dark-style .heading8:before {
	background: #222
}

.dark .page-heading.dark-style .heading2:after, .dark .page-heading.dark-style .heading2:before, .dark .page-heading.dark-style .heading8:after, .dark .page-heading.dark-style .heading8:before {
	background: #fff
}

.page-heading.dark-style .heading3:after {
	background: #222
}

.dark .page-heading.dark-style .heading3:after {
	background: #fff
}

.page-heading.dark-style .heading3:before {
	border-color: #222 transparent transparent
}

.dark .page-heading.dark-style .heading3:before {
	border-color: #fff transparent transparent
}

.page-heading.dark-style .heading4:after, .page-heading.dark-style .heading4:before {
	color: #222
}

.dark .page-heading.dark-style .heading4:after, .dark .page-heading.dark-style .heading4:before {
	color: #fff
}

.page-heading.dark-style .heading6:after {
	background: #222
}

.dark .page-heading.dark-style .heading6:after {
	background: #fff
}

.page-heading.dark-style .heading7:after, .page-heading.dark-style .heading7:before {
	border-color: #222
}

.dark .page-heading.dark-style .heading7:after, .dark .page-heading.dark-style .heading7:before {
	border-color: #fff
}

.page-heading.dark-style .dot {
	color: #222
}

.dark .page-heading.dark-style .dot {
	color: #fff
}

.page-banner {
	z-index: 1;
	color: #fff;
	font-size: 15px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 2px
}

.page-banner .heading {
	color: #fff;
	margin: 0 0 20px;
	padding: 0 0 14px;
	font-family: inherit;
	line-height: 35px;
	font-weight: 300;
	font-style: normal;
	position: relative;
	z-index: 1
}

.page-banner .heading:before {
	top: 100%;
	z-index: -1;
	bottom: auto;
	left: 0;
	right: auto;
	background: 0 0;
	margin: 0;
	width: 50px;
	height: 2px
}

.page-banner .parallax-holder, .page-banner .video-area {
	z-index: 1;
	position: relative;
	position: absolute
}

.page-banner .parallax-holder:before, .page-banner .stretch:before, .page-banner .video-area:before {
	position: absolute;
	content: "";
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1
}

.page-banner .parallax-holder, .page-banner .video-area {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0
}

.page-banner .container, .page-banner .container-max {
	padding-top: 187px;
	padding-bottom: 99px;
	position: relative;
	z-index: 999
}

.page-banner p {
	margin: 0 0 10px
}

.page-banner .holder {
	float: left;
	max-width: 850px;
	text-transform: none
}

.page-banner .breadcrumbs {
	margin: 0;
	float: right;
	font-size: 12px;
	font-weight: 600;
	line-height: 18px;
	padding: 5px 0 0;
	letter-spacing: 2px;
	text-transform: uppercase
}

.page-banner .breadcrumbs li {
	position: relative;
	padding-left: 9px;
	padding-right: 10px
}

.page-banner .breadcrumbs li:before {
	position: absolute;
	top: 0;
	bottom: auto;
	left: -3px;
	right: auto;
	content: "/"
}

.page-banner .breadcrumbs li:first-child a {
	margin-left: 0
}

.page-banner .breadcrumbs li:last-child a {
	margin-right: 0
}

.page-banner .breadcrumbs li a {
	color: #dbdbdb;
	-webkit-transition: all .4s ease;
	transition: all .4s ease
}

.page-banner.small .container, .page-banner.small .container-max {
	padding-top: 60px;
	padding-bottom: 60px
}

.page-banner.small .heading {
	margin: 0;
	padding: 0;
	font-size: 32px;
	letter-spacing: 0;
	line-height: 40px
}

.page-banner.small .breadcrumbs {
	padding: 10px 3px 0 0
}

.page-banner.grey {
	color: #8f8f8f;
	background: #f1f1f1
}

.page-banner.grey .heading {
	color: #727070
}

.dark .page-banner.grey .heading, .page-banner.grey .breadcrumbs li a, .page-banner.grey .breadcrumbs li:before {
	color: #8f8f8f
}

.dark .page-banner.grey {
	background: #191919
}

.page-banner.rightalign .holder {
	float: right;
	text-align: right
}

.page-banner.rightalign .breadcrumbs {
	float: left
}

.page-banner.center .breadcrumbs, .page-banner.center .holder {
	float: none;
	max-width: 100%;
	text-align: center
}

.page-banner.rightalign .heading:before {
	right: 0;
	left: auto
}

.page-banner.center .heading:before {
	left: 50%;
	margin: 0 0 0 -25px
}

.page-banner.style2.center .heading:before, .page-banner.style2.rightalign .heading:before {
	margin: 0;
	left: 0
}

.page-banner.center .breadcrumbs {
	overflow: hidden;
	padding: 10px 0 0
}

.page-banner.style2 .container, .page-banner.style2 .container-max {
	padding-top: 50px;
	padding-bottom: 40px
}

.page-banner.style2 .heading {
	font-size: 34px;
	padding: 0 0 7px
}

.page-banner.style2 .heading:before {
	right: 0;
	width: auto
}

.page-banner.style2 .breadcrumbs {
	font-size: 12px;
	padding: 39px 0 0
}

.page-banner.style2.center .heading {
	vertical-align: top;
	display: inline-block
}

.page-banner.dark {
	background: #222
}

.parallax-holder {
	overflow: hidden
}

.parallax-holder.no-parallax .parallax-frame {
	background-attachment: inherit !important;
	background-position: 50% 50% !important
}

#gotoTop, #pre-loader {
	z-index: 9999;
	position: fixed;
	text-align: center
}

#gotoTop {
	right: 20px;
	bottom: 20px;
	opacity: .8;
	color: #fff;
	font-size: 20px;
	cursor: pointer;
	background: #666;
	padding: 12px 0 0;
	border-radius: 50px;
	width: 50px;
	height: 50px;
	-webkit-transition: all .4s ease;
	transition: all .4s ease
}

#pre-loader {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: block;
	background: #fff
}

#pre-loader .loader-holder {
	top: 50%;
	left: 50%;
	width: 400px;
	height: 400px;
	position: absolute;
	margin: -200px 0 0 -200px;
	white-space: nowrap
}

.beans-slider .beans-slide, .popup-holder {
	position: relative
}

#pre-loader .loader-holder .frame {
	display: inline-block;
	white-space: normal;
	vertical-align: middle
}

#pre-loader .loader-holder:before {
	content: "";
	vertical-align: middle;
	display: inline-block;
	height: 100%;
	width: 1px;
	overflow: hidden;
	margin: 0 0 0 -5px
}

#pre-loader img {
	margin: 0 0 45px;
	display: inline-block
}

.no-transition #pre-loader {
	display: none !important
}

.popup-holder {
	overflow: hidden;
	height: 0
}

.beans-slider .slick-dots {
	left: 0;
	right: 0;
	z-index: 99;
	bottom: 15px;
	text-align: center;
	position: absolute
}

.beans-slider .parallax-holder, .beans-slider .stretch {
	z-index: -1
}

.beans-stepslider .slick-dots {
	left: 0;
	right: 0;
	z-index: 99;
	bottom: 15px;
	text-align: center;
	position: absolute
}

.beans-fadeslider .slide {
	z-index: 1
}

.beans-fadeslider .slide.active {
	z-index: 2
}

.beans-fadeslider .slick-dots {
	margin: 0;
	top: auto;
	z-index: 99;
	bottom: 40px;
	text-align: center
}

.boxed-v #wrapper {
	max-width: 1350px
}

.boxed-v.no-boxed #wrapper {
	max-width: 100%
}

.container-max {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1200px
}

.padding-b-zero, .padding-bottom-zero {
	padding-bottom: 0 !important
}

body.boxed-v #wrapper {
	max-width: 1350px
}

.mejs-offscreen {
	display: none
}

.video-lightbox {
	width: 900px
}

.port-parallex {
	height: 572px
}

.port-parallex.small {
	height: 460px
}

.section.dark {
	background: #191919
}

.section.parallax-holder, .section.video-area {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	position: absolute
}

.section.parallax-holder:before, .section.video-area:before {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: "";
	position: absolute;
	background: rgba(0, 0, 0, .7)
}

.margin-zero {
	margin: 0 !important
}

.allmargin {
	margin: 50px !important
}

.padding-zero {
	padding: 0 !important
}

.margin-t-zero {
	margin-top: 0 !important
}

.margin-left-zero {
	margin-left: 0 !important
}

.margin-right-zero {
	margin-right: 0 !important
}

.margin-top-30 {
	margin-top: 30px !important
}

.margin-top-60 {
	margin-top: 60px !important
}

.margin-top-90 {
	margin-top: 90px !important
}

.margin-b-zero {
	margin-bottom: 0 !important
}

.padding-top-zero {
	padding-top: 0 !important
}

.padding-top-10 {
	padding-top: 10px !important
}

.padding-top-20 {
	padding-top: 20px !important
}

.padding-top-30 {
	padding-top: 30px !important
}

.padding-top-60 {
	padding-top: 60px !important
}

.padding-top-90 {
	padding-top: 90px !important
}

.margin-top-100 {
	margin-top: 100px !important
}

.padding-top-100 {
	padding-top: 100px !important
}

.padding-right-zero {
	padding-right: 0 !important
}

.margin-bottom-10 {
	margin-bottom: 10px !important
}

.margin-bottom-20 {
	margin-bottom: 20px !important
}

.margin-bottom-30 {
	margin-bottom: 30px !important
}

.margin-bottom-60 {
	margin-bottom: 60px !important
}

.margin-bottom-90 {
	margin-bottom: 90px !important
}

.padding-bottom-10 {
	padding-bottom: 10px !important
}

.padding-bottom-20 {
	padding-bottom: 20px !important
}

.padding-bottom-30 {
	padding-bottom: 30px !important
}

.padding-bottom-50 {
	padding-bottom: 50px !important
}

.padding-bottom-60 {
	padding-bottom: 60px !important
}

.padding-bottom-90 {
	padding-bottom: 90px !important
}

.margin-bottom-100 {
	margin-bottom: 100px !important
}

.padding-bottom-100 {
	padding-bottom: 100px !important
}

.win-height {
	height: 100vh
}

.win-min-height {
	min-height: 100vh !important
}

.bg-none {
	background: 0 0 !important
}

.bg-white {
	background: #fff
}

.bg-grey {
	background: #f8f8f8
}

.bg-shark {
	background: #2a2a2a
}

.bg-dark-jungle, .bg-dark-jungle-bottom, .dark {
	background: #222
}

.dark .bg-grey {
	background: #2a2a2a
}

.dark .dark-bottom-border {
	border-bottom: 1px solid #494949
}

body.boxed-v #wrapper {
	background: #fff
}

body.dark.boxed-v #wrapper {
	background: #222
}

.no-bg, .no-bg:after, .no-bg:before {
	background: 0 0 !important
}

.no-color {
	color: inherit !important
}

.no-after-border:after {
	border-color: transparent !important
}

.border-zero {
	border: 0 !important
}

.color-white {
	color: #fff !important
}

.color-shark {
	color: #2a2a2a !important
}

.color-stack {
	color: #8f8f8f !important
}

.dark .color-shark {
	color: #fff !important
}

.playfair {
	font-family: "Playfair Display", "Helvetica Neue", Helvetica, Arial, sans-serif !important
}

.no-float {
	float: none !important
}

.lost-block {
	text-align: center;
	padding-top: 196px;
	padding-bottom: 209px
}

.lost-block h2 {
	margin: 0 0 60px;
	text-transform: uppercase;
	font: 900 100px/90px Lato, Georgia, "Times New Roman", Times, serif;
	text-shadow: 2px 2px 3px rgba(0, 0, 0, .5)
}

.lost-block h2 span {
	color: #2a2a2a
}

.dark .lost-block h2 span {
	color: #8f8f8f
}

.contact-block {
	padding-top: 100px;
	padding-bottom: 100px
}

.contact-block .contact-map {
	margin-bottom: 94px
}

.shop-form {
	float: right;
	width: 271px;
	padding: 4px 0 0
}

.shop-form .jcf-select {
	background: 0 0;
	border: solid #ddd;
	border-width: 1px 0;
	width: 100%;
	height: 41px;
	margin: 0
}

.dark .shop-form .jcf-select {
	border-color: #494949
}

.shop-form .jcf-select .jcf-select-text {
	color: #8f8f8f;
	font-size: 14px;
	line-height: 36px;
	font-weight: 300;
	margin: 0 32px 0 13px
}

.shop-form .jcf-select-opener {
	position: relative;
	position: absolute
}

.shop-form .jcf-select-opener:before {
	position: absolute;
	content: "";
	top: 50%;
	bottom: auto;
	left: 50%;
	right: auto;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 10px 6px 0;
	border-color: #ddd transparent transparent;
	margin: -6px 0 0 -13px
}

.cart-form .jcf-select .jcf-select-opener:before, .update-form .jcf-select .jcf-select-opener:before {
	top: 50%;
	bottom: auto;
	left: 50%;
	right: auto;
	content: "\f107";
	font-family: FontAwesome
}

.cart-form {
	padding: 22px 0 17px
}

.cart-form .form-col {
	float: left;
	width: 16.2%;
	margin: 0 3.3% 0 0
}

.cart-form .form-col.color {
	width: 30%
}

.cart-form label {
	color: #2a2a2a;
	display: block;
	margin: 0 0 8px;
	line-height: 18px
}

.dark .cart-form label {
	color: #fff
}

.cart-form .jcf-select {
	width: 100%;
	height: 38px;
	min-width: inherit;
	border-color: #ddd
}

.dark .cart-form .jcf-select {
	background: 0 0;
	border-color: #494949
}

.cart-form .jcf-select .jcf-select-text {
	color: #8f8f8f;
	line-height: 35px;
	margin: 0 30px 0 9px
}

.cart-form .jcf-select .jcf-select-opener {
	position: relative;
	position: absolute
}

.cart-form .jcf-select .jcf-select-opener:before {
	position: absolute;
	color: #8f8f8f;
	margin: -14px 0 0 -7px
}

.cart-form .btn {
	border: 0;
	float: left;
	color: #fff;
	font-size: 10px;
	margin: 0;
	background: #2a2a2a;
	height: 38px
}

.dark .cart-form .btn {
	background: #191919
}

.quantity-form {
	margin: 0 auto;
	display: block;
	background: #fff;
	border: 1px solid #ddd;
	width: 131px;
	height: 31px
}

.dark .quantity-form {
	color: #fff;
	background: #191919;
	border-color: #494949
}

.quantity-form .decrease {
	float: left;
	font-size: 22px;
	color: #2a2a2a;
	line-height: 27px;
	width: 32px;
	height: 29px
}

.dark .quantity-form .decrease {
	color: #fff
}

.quantity-form .decrease:hover {
	background: #ddd
}

.dark .quantity-form .decrease:hover {
	background: 0 0
}

.quantity-form .increase {
	float: left;
	color: #2a2a2a;
	font-size: 18px;
	line-height: 27px;
	width: 32px;
	height: 29px
}

.dark .quantity-form .increase {
	color: #fff
}

.quantity-form .increase:hover {
	background: #ddd;
	text-decoration: none
}

.dark .quantity-form .increase:hover {
	background: 0 0
}

.quantity-form .form-control {
	float: left;
	width: 65px;
	height: 29px;
	font-size: 12px;
	border: solid #ddd;
	border-width: 0 1px;
	border-radius: 0;
	box-shadow: none;
	color: #2a2a2a;
	text-align: center
}

.dark .quantity-form .form-control {
	color: #fff;
	background: #191919;
	border-color: #494949
}

.dark .quantity-form .placeholder, .quantity-form .placeholder {
	color: #2a2a2a
}

.dark .quantity-form ::-webkit-input-placeholder {
	color: #2a2a2a
}

.dark .quantity-form ::-moz-placeholder {
	opacity: 1;
	color: #2a2a2a
}

.dark .quantity-form :-moz-placeholder {
	color: #2a2a2a
}

.dark .quantity-form :-ms-input-placeholder {
	color: #2a2a2a
}

.quantity-form ::-webkit-input-placeholder {
	color: #2a2a2a
}

.quantity-form ::-moz-placeholder {
	opacity: 1;
	color: #2a2a2a
}

.quantity-form :-moz-placeholder {
	color: #2a2a2a
}

.quantity-form :-ms-input-placeholder {
	color: #2a2a2a
}

.apply-form {
	float: left;
	width: 416px
}

.apply-form .form-control {
	border-radius: 0;
	float: left;
	color: #8f8f8f;
	font-size: 13px;
	line-height: 18px;
	box-shadow: none;
	border-color: #ddd;
	padding: 6px 22px;
	width: 77.6%;
	height: 40px
}

.apply-form .form-control ::-webkit-input-placeholder {
	color: #8f8f8f
}

.apply-form .form-control ::-moz-placeholder {
	opacity: 1;
	color: #8f8f8f
}

.apply-form .form-control :-moz-placeholder {
	color: #8f8f8f
}

.apply-form .form-control :-ms-input-placeholder {
	color: #8f8f8f
}

.apply-form .form-control .placeholder {
	color: #8f8f8f
}

.dark .apply-form .form-control {
	color: #fff;
	background: #191919;
	border-color: #494949
}

.dark .apply-form .form-control ::-webkit-input-placeholder {
	color: #fff
}

.dark .apply-form .form-control ::-moz-placeholder {
	opacity: 1;
	color: #fff
}

.dark .apply-form .form-control :-moz-placeholder {
	color: #fff
}

.dark .apply-form .form-control :-ms-input-placeholder {
	color: #fff
}

.dark .apply-form .form-control .placeholder {
	color: #fff
}

.apply-form .btn {
	border: 0;
	margin: 0;
	float: right;
	color: #fff;
	padding: 6px 12px;
	background: #2a2a2a;
	width: 20.2%;
	height: 40px
}

.update-form {
	margin: 0
}

.update-form .jcf-select {
	margin: 0 0 6px;
	border-color: #ddd;
	width: 100%;
	height: 40px
}

.dark .update-form .jcf-select {
	border-color: #494949;
	background: #191919
}

.update-form .jcf-select .jcf-select-text {
	color: #8f8f8f;
	font-size: 14px;
	line-height: 35px;
	margin: 0 42px 0 21px
}

.dark .update-form .jcf-select .jcf-select-text {
	color: #fff
}

.update-form .jcf-select .jcf-select-opener {
	position: relative;
	position: absolute
}

.update-form .jcf-select .jcf-select-opener:before {
	position: absolute;
	font-size: 16px;
	margin: -12px 0 0 -25px
}

.update-form .form-control {
	width: 100%;
	height: 40px;
	border-radius: 0;
	font-size: 14px;
	line-height: 20px;
	margin: 0 0 6px;
	padding: 6px 20px;
	resize: none;
	color: #8f8f8f;
	border-color: #ddd
}

.update-form .form-control ::-webkit-input-placeholder {
	color: #8f8f8f
}

.update-form .form-control ::-moz-placeholder {
	opacity: 1;
	color: #8f8f8f
}

.update-form .form-control :-moz-placeholder {
	color: #8f8f8f
}

.update-form .form-control :-ms-input-placeholder {
	color: #8f8f8f
}

.update-form .form-control .placeholder {
	color: #8f8f8f
}

.dark .update-form .form-control .placeholder, .dark .update-form .placeholder {
	color: #fff
}

.dark .update-form .form-control {
	border-color: #494949;
	background: #191919
}

.dark .update-form .form-control ::-webkit-input-placeholder {
	color: #fff
}

.dark .update-form .form-control ::-moz-placeholder {
	opacity: 1;
	color: #fff
}

.dark .update-form .form-control :-moz-placeholder {
	color: #fff
}

.dark .update-form .form-control :-ms-input-placeholder {
	color: #fff
}

.dark .update-form ::-webkit-input-placeholder {
	color: #fff
}

.dark .update-form ::-moz-placeholder {
	opacity: 1;
	color: #fff
}

.dark .update-form :-moz-placeholder {
	color: #fff
}

.dark .update-form :-ms-input-placeholder {
	color: #fff
}

.update-form textarea.form-control {
	height: 100px
}

.update-form .btn {
	border: 0;
	color: #fff;
	margin: 0 0 6px;
	padding: 6px 12px;
	background: #2a2a2a;
	width: 100%;
	height: 40px
}

.dark .update-form .btn {
	background: #191919
}

.update-form .label-checks {
	display: block;
	margin: 0 0 6px;
	padding: 7px 0 0;
	font-size: 14px;
	line-height: 18px;
	font-weight: 400
}

.update-form .label-checks .txt {
	color: #2a2a2a;
	display: inline-block;
	vertical-align: top
}

.dark .update-form .label-checks .txt {
	color: #fff
}

.comment-form .input, .comment-form textarea {
	float: left;
	outline: 0;
	color: #8f8f8f;
	font-size: 13px;
	line-height: 20px
}

.update-form input[type=checkbox] {
	margin: 0 7px 0 0
}

.comment-form {
	overflow: hidden;
	margin: 0 0 104px
}

.comment-form h4 {
	margin: 0 0 24px
}

.comment-form .form-row {
	margin: 0 0 31px
}

.comment-form .input {
	width: 31.7%;
	height: 40px;
	padding: 10px 16px;
	margin: 0 0 0 2.4%;
	background: #f8f8f8;
	border: 1px solid #ddd
}

.dark .comment-form .input {
	background: #191919;
	border-color: #494949
}

.comment-form .input:first-child {
	margin: 0
}

.comment-form ::-webkit-input-placeholder {
	color: #8f8f8f
}

.comment-form ::-moz-placeholder {
	color: #8f8f8f;
	opacity: 1
}

.comment-form :-ms-input-placeholder {
	color: #8f8f8f
}

.comment-form textarea {
	resize: none;
	overflow: auto;
	margin: 0 0 21px;
	padding: 10px 20px;
	background: #f8f8f8;
	width: 99.8% !important;
	height: 111px !important;
	border: 1px solid #ddd
}

.dark .comment-form textarea {
	background: #191919;
	border-color: #494949
}

.comment-form textarea:first-child {
}

.comment-form .btn {
	color: #fff
}

.comment-form .btn:hover {
	background: #cd2122
}

.contact-form {
	z-index: 5;
	color: #fff;
	padding: 92px 0 100px
}

.contact-form .parallax-holder, .contact-form .stretch {
	z-index: -1
}

.contact-form .form-control, .contact-form .jcf-select {
	color: #fff;
	height: 40px;
	font-size: 13px;
	margin: 0 0 30px;
	border-radius: 0;
	background: 0 0;
	text-transform: uppercase;
	border: 1px solid #fff
}

.contact-form .form-control.error, .contact-form .jcf-select.error {
	color: red;
	border-color: red
}

.contact-form .form-control.error::-webkit-input-placeholder, .contact-form .jcf-select.error::-webkit-input-placeholder {
	color: red
}

.contact-form .form-control.error:-moz-placeholder, .contact-form .jcf-select.error:-moz-placeholder {
	color: red
}

.contact-form .form-control.error::-moz-placeholder, .contact-form .jcf-select.error::-moz-placeholder {
	color: red;
	opacity: 1
}

.contact-form .form-control.error:-ms-input-placeholder, .contact-form .jcf-select.error:-ms-input-placeholder {
	color: red
}

.contact-form .form-control::-webkit-input-placeholder {
	color: #fff
}

.contact-form .form-control:-moz-placeholder {
	color: #fff
}

.contact-form .form-control::-moz-placeholder {
	color: #fff;
	opacity: 1
}

.contact-form .form-control:-ms-input-placeholder {
	color: #fff
}

.contact-form textarea.form-control {
	resize: none;
	overflow: auto;
	width: 100% !important;
	height: 100px !important
}

.contact-form .btn-submit {
	color: #fff;
	font-size: 13px;
	line-height: 20px;
	padding: 10px 20px;
	text-transform: uppercase;
	border: 1px solid transparent;
	-webkit-transition: all .4s ease;
	transition: all .4s ease
}

.contact-form .btn-submit:hover {
	border-color: #fff;
	background: 0 0
}

.contact-form .overlay {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	background: rgba(0, 0, 0, .5);
	position: absolute
}

.contact-form .fa-calendar, .contact-form .fa-chevron-down {
	top: 12px;
	right: 26px;
	color: #fff;
	position: absolute
}

.contact-form .jcf-select {
	width: 100%
}

.contact-form .jcf-select .jcf-select-text {
	margin: 6px 35px 0 8px
}

.done-massage {
	background: #dff0d8;
	color: #468847;
	border: 1px solid #d6e9c6;
	border-radius: 10px;
	text-align: center;
	padding: 10px 5px;
	font-size: 14px;
	margin: 0 auto;
	display: none;
	width: 100%
}

.form-loading {
	float: right;
	height: 31px;
	width: 31px;
	display: none;
	margin-top: 8px;
	margin-left: 10px
}

.jcf-select, .woocommerce-ordering .jcf-select {
	display: inline-block;
	vertical-align: top;
	position: relative;
	min-width: 150px
}

.jcf-select {
	border: 1px solid #777;
	background: #fff;
	margin: 0 0 12px;
	height: 26px
}

.woocommerce-ordering .jcf-select {
	border: 1px solid #eaeaea;
	background: #fff;
	margin: 0 0 31px;
	height: 42px;
	padding: 7px;
	float: right
}

.jcf-select select {
	z-index: 1;
	left: 0;
	top: 0
}

.jcf-select .jcf-select-text {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	cursor: default;
	display: block;
	font-size: 13px;
	line-height: 26px;
	margin: 0 35px 0 8px
}

.jcf-select .jcf-select-opener {
	position: absolute;
	text-align: center;
	width: 26px;
	bottom: 0;
	right: 0;
	top: 0
}

.jcf-list, .jcf-list .jcf-list-content {
	display: inline-block;
	width: 100%;
	vertical-align: top
}

body > .jcf-select-drop {
	position: absolute;
	margin: -1px 0 0;
	z-index: 9999
}

body > .jcf-select-drop.jcf-drop-flipped {
	margin: 1px 0 0
}

.jcf-select .jcf-select-drop {
	position: absolute;
	margin-top: 0;
	z-index: 9999;
	top: 100%;
	left: -1px;
	right: -1px
}

.jcf-select .jcf-drop-flipped {
	bottom: 100%;
	top: auto
}

.jcf-list-box {
	overflow: hidden;
	display: inline-block;
	border: 1px solid #b8c3c9;
	min-width: 200px;
	margin: 0 15px
}

.jcf-list {
	position: relative;
	background: #fff;
	line-height: 14px;
	font-size: 12px
}

.jcf-list .jcf-list-content {
	overflow: auto
}

.jcf-list ul {
	list-style: none;
	padding: 0;
	margin: 0
}

.jcf-list .jcf-optgroup-caption, .jcf-list .jcf-option {
	white-space: nowrap;
	padding: 5px 9px;
	cursor: default;
	display: block
}

.jcf-list ul li {
	overflow: hidden;
	display: block
}

.jcf-list .jcf-overflow {
	overflow: auto
}

.jcf-list .jcf-option {
	overflow: hidden;
	color: #656565;
	height: 1%
}

.jcf-list .jcf-disabled {
	background: #fff !important;
	color: #aaa !important
}

.jcf-list-box .jcf-selected, .jcf-select-drop .jcf-hover {
	background: #e6e6e6;
	color: #000
}

.jcf-list .jcf-optgroup-caption {
	font-weight: 700;
	color: #000
}

.jcf-list .jcf-optgroup .jcf-option {
	padding-left: 30px
}

.jcf-range {
	display: block;
	overflow: hidden
}

.jcf-range .jcf-range-track {
	margin: 0 20px 0 0;
	position: relative;
	display: block
}

.jcf-range .jcf-range-wrapper {
	background: #ddd;
	display: block;
	margin: 5px 0;
	height: 10px
}

.dark .jcf-range .jcf-range-wrapper {
	background: #191919
}

.jcf-range.jcf-vertical {
	width: auto
}

.jcf-range.jcf-vertical .jcf-range-wrapper {
	margin: 0;
	width: 10px;
	height: auto;
	padding: 20px 0 0
}

.jcf-range.jcf-vertical .jcf-range-track {
	height: 180px;
	width: 10px
}

.jcf-range.jcf-vertical .jcf-range-handle {
	left: -5px;
	top: auto
}

.jcf-range .jcf-range-handle {
	position: absolute;
	background: #ddd;
	border-radius: 19px;
	border: 3px solid #e74c3c;
	width: 19px;
	height: 19px;
	margin: -4px 0 0;
	z-index: 1;
	top: 0;
	left: 0
}

.jcf-range .jcf-range-handle:after {
	top: 1px;
	right: 14px;
	content: "";
	bottom: 1px;
	left: -9999px;
	position: absolute;
	background: #e74c3c
}

.jcf-range .jcf-range-mark {
	position: absolute;
	overflow: hidden;
	background: #000;
	width: 1px;
	height: 3px;
	top: -7px;
	margin: 0 0 0 9px
}

.jcf-range.jcf-vertical .jcf-range-mark {
	margin: 0 0 9px;
	left: 14px;
	top: auto;
	width: 3px;
	height: 1px
}

.jcf-range.jcf-disabled {
	background: 0 0 !important;
	opacity: .3
}

.jcf-number {
	display: inline-block;
	position: relative;
	height: 38px;
	overflow: hidden
}

.jcf-number input {
	-moz-appearance: textfield;
	box-sizing: border-box;
	border: 1px solid #ddd;
	height: 38px;
	padding: 3px 27px 3px 7px;
	margin: 0;
	width: 100%;
	background: 0 0
}

.jcf-number input::-webkit-inner-spin-button, .jcf-number input::-webkit-outer-spin-button {
	-webkit-appearance: none
}

.dark .jcf-number input {
	border-color: #494949
}

.jcf-number .jcf-btn-dec, .jcf-number .jcf-btn-inc {
	position: absolute;
	width: 20px;
	height: 15px;
	right: 1px;
	top: 7px
}

.jcf-number .jcf-btn-dec {
	top: auto;
	bottom: 7px
}

.jcf-number.jcf-disabled .jcf-btn-dec:hover, .jcf-number.jcf-disabled .jcf-btn-inc:hover {
	background: #aaa
}

.jcf-number .jcf-btn-dec:before, .jcf-number .jcf-btn-inc:before {
	position: absolute;
	content: "";
	width: 0;
	height: 0;
	top: 50%;
	left: 50%;
	margin: -6px 0 0 -4px;
	border: 4px solid #aaa;
	border-color: transparent transparent #000
}

.jcf-number .jcf-btn-dec:before {
	margin: -1px 0 0 -4px;
	border-color: #000 transparent transparent
}

.jcf-number .jcf-btn-dec.jcf-disabled:before, .jcf-number .jcf-btn-inc.jcf-disabled:before, .jcf-number.jcf-disabled .jcf-btn-dec:before, .jcf-number.jcf-disabled .jcf-btn-inc:before {
	opacity: .3
}

.jcf-number.jcf-disabled input {
	background: #ddd
}

.youtube_bg_video {
	position: absolute !important;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0
}

#style-changer {
	top: 186px;
	left: 0;
	width: 244px;
	z-index: 9999;
	position: fixed;
	background: #fff;
	margin: 0 0 0 -245px;
	border: 1px solid #eee;
	padding: 16px 17px 5px;
	-webkit-transition: margin .4s ease;
	transition: margin .4s ease;
	box-shadow: 10px 10px 0 0 rgba(122, 122, 122, .1)
}

.dark #style-changer {
	background: #222;
	border-color: #494949;
	box-shadow: 10px 10px 0 0 rgba(70, 70, 70, .1)
}

#style-changer .title {
	color: #222;
	display: block;
	margin: 0 0 15px;
	padding: 0 0 8px;
	text-transform: uppercase;
	border-bottom: 1px solid #eee;
	font: 500 16px/24px Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif
}

.dark #style-changer .title {
	color: #fff;
	border-color: #494949
}

#style-changer .subtitle {
	color: #8f8f8f;
	display: block;
	margin: 0 0 13px;
	letter-spacing: 2px;
	text-transform: uppercase;
	font: 500 13px/24px Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif
}

.dark #style-changer .subtitle {
	color: #fff
}

#style-changer .list-color {
	margin: 0 0 12px;
	padding: 0 0 14px;
	border-bottom: 1px solid #eee
}

.dark #style-changer .list-color {
	border-color: #494949
}

#style-changer .list-color li {
	padding: 0 1px 4px 5px
}

#style-changer .list-color a {
	display: block;
	border-radius: 3px;
	width: 33px;
	height: 33px
}

#style-changer .list-color a:hover {
	opacity: .7
}

#style-changer .list-style {
	margin: 0 0 14px;
	padding: 0 0 19px;
	border-bottom: 1px solid #eee
}

.dark #style-changer .list-style {
	border-color: #494949
}

#style-changer .list-style li {
	padding: 0 5px 0 2px
}

#style-changer .list-style .btn, #style-changer .list-style .btn2 {
	width: 95px;
	padding: 4px;
	display: block;
	font-size: 12px;
	text-transform: uppercase;
	text-align: center
}

#style-changer .list-style li.dark {
	background: 0 0
}

#style-changer .list-style .btn {
	color: #fff;
	border-radius: 0;
	background: #222;
	border: 1px solid #222
}

.dark #style-changer .list-style .btn {
	color: #fff;
	border-color: #494949
}

#style-changer .list-style .btn:hover {
	color: #222;
	background: 0 0
}

.dark #style-changer .list-style .btn:hover {
	color: #fff
}

#style-changer .list-style .btn.add {
	color: #222;
	background: 0 0
}

.dark #style-changer .list-style .btn.add {
	color: #fff;
	border-color: #494949
}

#style-changer .list-style .btn.add:hover {
	color: #fff;
	background: #222
}

#style-changer .list-style .btn2 {
	color: #fff;
	border-radius: 0;
	border: 1px solid transparent
}

#style-changer .list-style .btn2:hover {
	color: #222;
	border-color: #222;
	background: 0 0
}

.dark #style-changer .list-style .btn2:hover {
	color: #fff;
	border-color: #fff
}

#style-changer .list-style .btn2.add {
	color: #222;
	border-color: #222;
	background: 0 0
}

.dark #style-changer .list-style .btn2.add {
	color: #fff;
	border-color: #fff
}

#style-changer .list-style .btn2.add:hover {
	color: #fff
}

#style-changer .list-style.add {
	border: 0;
	padding: 0
}

#style-changer a {
	-webkit-transition: all .4s ease;
	transition: all .4s ease
}

#style-changer .changer-opener {
	left: 100%;
	font-size: 25px;
	padding: 10px 0 0;
	text-align: center;
	position: absolute;
	background: #fff;
	border-radius: 0 5px 5px 0;
	width: 49px;
	height: 54px;
	top: 50px;
	border-right: 1px solid #eee;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	box-shadow: 10px 10px 0 0 rgba(122, 122, 122, .1)
}

.dark #style-changer .changer-opener {
	background: #222;
	border-color: #494949;
	box-shadow: 10px 10px 0 0 rgba(70, 70, 70, .1)
}

#style-changer .awesome a {
	background: #ff1a56
}

#style-changer .bleu-de-france a {
	background: #3498db
}

#style-changer .bleu-de-france2 a {
	background: #4285f6
}

#style-changer .chateau-green a {
	background: #27ae60
}

#style-changer .dark-pastel-red a {
	background: #c0392b
}

#style-changer .di-serria a {
	background: #cea35f
}

#style-changer .light-green a {
	background: #2ecc71
}

#style-changer .light-taupe a {
	background: #ad8368
}

#style-changer .niagara a {
	background: #16a085
}

#style-changer .orange a {
	background: #f96015
}

#style-changer .pastel-orange a {
	background: #ffb546
}

#style-changer .pastel-red a, #style-changer .pastel-red2 a {
	background: #ff6d6d
}

#style-changer .rich-electric-blue a {
	background: #0091ea
}

#style-changer .rodeo-dust a {
	background: #c7b29b
}

#style-changer .sun a {
	background: #feb010
}

#style-changer .sunglo a {
	background: #d86d65
}

#style-changer .twine a {
	background: #c59d5f
}

#style-changer .ucla-gold a {
	background: #ffb600
}

#style-changer .yellow a {
	background: #f39c12
}

#style-changer .zest a {
	background: #e67e22
}

#style-changer .my-sin a {
	background: #feb41c
}

#ui-datepicker-div {
	z-index: 9999 !important
}

@media only screen and (max-width: 1219px) {
	.error-section.style7 .logo {
		margin: 0 0 30px;
		position: static
	}

	.error-section.style7 h1 {
		margin: 0 0 40px
	}

	.error-section.style7 .text {
		margin: 0
	}

	.gallery-quotes {
		font-size: 12px;
		line-height: 20px
	}
}

@media only screen and (max-width: 991px) {
	.margin-bottom-100, .margin-bottom-90 {
		margin-bottom: 60px !important
	}

	.margin-top-100, .margin-top-90 {
		margin-top: 60px !important
	}

	.page-banner .heading, .parallax-section h2 {
		font-size: 30px;
		line-height: 35px
	}

	.parallax-section h3 {
		font-size: 25px;
		line-height: 30px
	}

	.padding-sm-zero {
		padding: 0 !important
	}

	.padding-sm-topzero {
		padding-top: 0 !important
	}

	.padding-sm-bottomzero {
		padding-bottom: 0 !important
	}

	.padding-bottom-100, .padding-bottom-90, .padding-bottomsm-60 {
		padding-bottom: 60px !important
	}

	.padding-top-100, .padding-top-90 {
		padding-top: 60px !important
	}

	.allmargin {
		margin: 30px !important
	}

	.contact-block {
		padding-top: 20px;
		padding-bottom: 50px
	}

	.contact-block .contact-map {
		margin-bottom: 30px
	}

	.contact-address .alignleft {
		float: none;
		margin: 0 0 10px
	}

	.contact-address .alignleft img {
		width: 100%
	}

	.apply-form {
		width: 48%
	}
}

@media only screen and (max-width: 767px) {
	.page-banner .breadcrumbs, .page-banner .holder {
		max-width: 100%;
		float: none
	}

	.tel {
		pointer-events: auto
	}

	.page-heading {
		margin: 0 auto 30px;
		font-size: 14px;
		line-height: 18px
	}

	.page-heading h1 {
		font-size: 30px;
		line-height: 40px
	}

	.page-heading h2 {
		font-size: 25px;
		line-height: 30px
	}

	.page-heading h3 {
		font-size: 20px;
		line-height: 25px
	}

	.page-heading h4 {
		font-size: 18px;
		line-height: 25px
	}

	.page-heading h5, .page-heading h6 {
		font-size: 15px;
		line-height: 20px
	}

	.page-heading .heading11 {
		font-size: 45px;
		line-height: 60px
	}

	.page-banner .breadcrumbs {
		overflow: hidden;
		padding: 15px 0 0
	}

	.page-banner.small .heading {
		font-size: 25px;
		line-height: 35px
	}

	.page-banner.rightalign .holder {
		float: none;
		text-align: left
	}

	.page-banner.rightalign .breadcrumbs {
		float: none
	}

	.beans-fadeslider .slick-dots {
		bottom: 53px
	}

	.parallax-section {
		font-size: 14px;
		line-height: 20px
	}

	.parallax-section h2 {
		font-size: 20px;
		line-height: 25px
	}

	.parallax-section h3 {
		font-size: 18px;
		line-height: 20px
	}

	.clearfix-sm {
		clear: left
	}

	.padding-xs-zero {
		padding: 0 !important
	}

	.error-section {
		font-size: 10px;
		line-height: 15px;
		height: auto !important
	}

	.error-section.style4 .wedding-nav .countdown-section {
		margin: 0 1px
	}

	.error-section.style4 .wedding-nav .countdown-section .countdown-amount {
		font-size: 30px;
		margin: 0 0 12px;
		line-height: 60px;
		width: 65px;
		height: 65px
	}

	.error-section.style5 .wedding-nav {
		margin: 0 0 50px
	}

	.error-section.style5 .wedding-nav .countdown-section {
		margin: 0 1px
	}

	.error-section.style5 .wedding-nav .countdown-section .countdown-amount {
		font-size: 38px;
		line-height: 40px;
		margin: 0 0 12px
	}

	.error-section.style7 h1 {
		font-size: 30px;
		margin: 0 0 10px;
		line-height: 40px;
		letter-spacing: 2px
	}

	.error-section .next-section {
		display: none
	}

	.error-section .container {
		height: auto !important
	}

	.error-section .logo {
		margin: 0 0 27px
	}

	.error-section h1, .error-section h2 {
		font-size: 15px
	}

	.error-section .error-info {
		font-size: 18px;
		line-height: 24px;
		margin: 0 0 27px
	}

	.error-section .error-info .error-code {
		font-size: 50px;
		line-height: 50px
	}

	.error-section .text {
		padding: 0 0 9px
	}

	.error-section .text2 {
		font-size: 12px;
		line-height: 18px
	}

	.error-section .footer-social li {
		margin: 0 7px
	}

	.error-section .wedding-nav {
		font-size: 10px;
		line-height: 14px
	}

	.error-section .wedding-nav .countdown-section {
		margin: 0 1px;
		padding: 5px 10px;
		width: 68px;
		height: 106px
	}

	.error-section .wedding-nav .countdown-section .countdown-amount {
		font-size: 38px;
		line-height: 40px
	}

	.lost-block {
		padding-top: 100px;
		padding-bottom: 100px
	}

	.lost-block h2 {
		font-size: 40px;
		line-height: 50px
	}

	.error-form {
		width: 280px
	}

	.apply-form {
		width: 100%;
		float: none;
		margin: 0 0 20px
	}

	.comment-form .input {
		width: 100%;
		margin: 0 0 10px
	}

	.comment-form .input:first-child {
		margin: 0 0 10px
	}

	.blockquote-style1 {
		padding: 20px
	}

	.blockquote-style1 q {
		font-size: 18px;
		line-height: 25px
	}

	.blockquote-style2 {
		padding: 22px
	}
}

@media only screen and (max-width: 479px) {
	.testimonials .holder, .testimonials .img-box {
		display: block;
		width: 100%
	}

	.testimonials .img-box img {
		width: 100%
	}
}

@-webkit-keyframes hvr-ripple-out {
	0%, 67% {
		top: -5px
	}
	100%, 33% {
		top: 5px
	}
}

@keyframes hvr-ripple-out {
	0%, 67% {
		top: -5px
	}
	100%, 33% {
		top: 5px
	}
}

@-webkit-keyframes bouncedelay {
	0%, 100%, 80% {
		-webkit-transform: scale(0)
	}
	40% {
		-webkit-transform: scale(1)
	}
}

@keyframes bouncedelay {
	0%, 100%, 80% {
		transform: scale(0);
		-webkit-transform: scale(0)
	}
	40% {
		transform: scale(1);
		-webkit-transform: scale(1)
	}
}

@-webkit-keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg)
	}
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg)
	}
}

@-webkit-keyframes bounce {
	0%, 100% {
		-webkit-transform: scale(0)
	}
	50% {
		-webkit-transform: scale(1)
	}
}

@keyframes bounce {
	0%, 100% {
		transform: scale(0);
		-webkit-transform: scale(0)
	}
	50% {
		transform: scale(1);
		-webkit-transform: scale(1)
	}
}

@-webkit-keyframes rotateplane {
	0% {
		-webkit-transform: perspective(120px)
	}
	50% {
		-webkit-transform: perspective(120px) rotateY(180deg)
	}
	100% {
		-webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
	}
}

@keyframes rotateplane {
	0% {
		transform: perspective(120px) rotateX(0) rotateY(0);
		-webkit-transform: perspective(120px) rotateX(0) rotateY(0)
	}
	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0)
	}
	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg)
	}
}

@-webkit-keyframes cubemove {
	25% {
		-webkit-transform: translateX(42px) rotate(-90deg) scale(.5)
	}
	50% {
		-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg)
	}
	75% {
		-webkit-transform: translateX(0) translateY(42px) rotate(-270deg) scale(.5)
	}
	100% {
		-webkit-transform: rotate(-360deg)
	}
}

@keyframes cubemove {
	25% {
		transform: translateX(42px) rotate(-90deg) scale(.5);
		-webkit-transform: translateX(42px) rotate(-90deg) scale(.5)
	}
	50% {
		transform: translateX(42px) translateY(42px) rotate(-179deg);
		-webkit-transform: translateX(42px) translateY(42px) rotate(-179deg)
	}
	50.1% {
		transform: translateX(42px) translateY(42px) rotate(-180deg);
		-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg)
	}
	75% {
		transform: translateX(0) translateY(42px) rotate(-270deg) scale(.5);
		-webkit-transform: translateX(0) translateY(42px) rotate(-270deg) scale(.5)
	}
	100% {
		transform: rotate(-360deg);
		-webkit-transform: rotate(-360deg)
	}
}

@-webkit-keyframes stretchdelay {
	0%, 100%, 40% {
		-webkit-transform: scaleY(.4)
	}
	20% {
		-webkit-transform: scaleY(1)
	}
}

@keyframes stretchdelay {
	0%, 100%, 40% {
		transform: scaleY(.4);
		-webkit-transform: scaleY(.4)
	}
	20% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1)
	}
}

@-webkit-keyframes stretchdelay {
	0%, 100%, 40% {
		-webkit-transform: translateY(-10px)
	}
	20% {
		-webkit-transform: translateY(-20px)
	}
}

@keyframes stretchdelay {
	0%, 100%, 40% {
		transform: translateY(-10px);
		-webkit-transform: translateY(-10px)
	}
	20% {
		transform: translateY(-20px);
		-webkit-transform: translateY(-20px)
	}
}

.alignnone {
	margin: 5px 20px 20px 0
}

.aligncenter, div.aligncenter {
	display: block;
	margin: 5px auto
}

.alignright {
	float: right;
	margin: 5px 0 20px 20px
}

.alignleft {
	float: left;
	margin: 5px 20px 20px 0
}

a img.alignright {
	float: right;
	margin: 5px 0 20px 20px
}

a img.alignleft, a img.alignnone {
	margin: 5px 20px 20px 0
}

a img.alignleft {
	float: left
}

a img.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto
}

.wp-caption {
	background: #fff;
	border: 1px solid #f0f0f0;
	max-width: 96%;
	padding: 5px 3px 10px;
	text-align: center
}

.wp-caption.alignleft, .wp-caption.alignnone {
	margin: 5px 20px 20px 0
}

.wp-caption.alignright {
	margin: 5px 0 20px 20px
}

.wp-caption img {
	border: 0;
	height: auto;
	margin: 0;
	max-width: 98.5%;
	padding: 0;
	width: auto
}

.wp-caption p.wp-caption-text {
	font-size: 11px;
	line-height: 17px;
	margin: 0;
	padding: 0 4px 5px
}

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: .875rem;
	font-weight: 700;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	top: 5px;
	width: auto;
	z-index: 100000
}

.wp-pagenavi {
	clear: both;
	text-align: center
}

.wp-pagenavi a, .wp-pagenavi span {
	border: 1px solid #BFBFBF;
	padding: 8px 13px;
	margin: 2px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 5px;
	border-radius: 3px;
	font-size: 13px
}

.wp-pagenavi a:hover {
	background: #2c2c2c;
	color: #FFF
}

.wp-pagenavi span.current {
	color: #FFF;
	font-weight: 700;
	background: #df5461
}

table {
	background: #fff;
	margin: 0 0 18px;
	border: 1px solid #ddd;
	width: 100%
}

table tfoot, table thead {
	background: #f5f5f5
}

table tbody tr td, table tfoot tr td, table tfoot tr th, table thead tr th, table tr td {
	display: table-cell;
	font-size: 14px;
	line-height: 18px;
	text-align: left
}

table tfoot tr td, table thead tr th {
	padding: 8px 10px 9px;
	font-size: 14px;
	font-weight: 700;
	color: #222
}

table tfoot tr td:first-child, table thead tr th:first-child {
	border-left: none
}

table tfoot tr td:last-child, table thead tr th:last-child {
	border-right: none
}

table tbody tr.alt, table tbody tr.even, table tbody tr:nth-child(even) {
	background: #f4f4f4
}

table tbody tr td {
	color: #333;
	padding: 9px 10px;
	vertical-align: top;
	border: none
}

td, th {
	margin: 0;
	padding: 0 0 0 10px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24.99px;
	text-transform: none;
	letter-spacing: 0;
	word-spacing: 0;
	color: #565656;
	direction: ltr
}

dl, ol, ul {
	line-height: 1.8;
	list-style-position: inside
}

ol li ol, ol li ul, ul li ol, ul li ul {
	margin-left: 0;
	margin-bottom: 0
}

ol {
	margin-left: 20px
}

embed, img, object {
	height: auto
}

input[type=date], input[type=email], input[type=password], input[type=tel], input[type=text], input[type=url], textarea {
	padding: 10px !important;
	width: 100%;
	border: 1px solid #e3e1e1;
	font-family: inherit;
	font-size: 16px !important;
	line-height: 22px;
	background-color: #FCFCFC;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .09) inset;
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .09) inset;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .09) inset;
	-o-box-shadow: 0 1px 2px rgba(0, 0, 0, .09) inset;
	transition: all .2s linear;
	-moz-transition: all .2s linear;
	-webkit-transition: all .2s linear;
	-o-transition: all .2s linear
}

input[type=search] {
	padding: 9px !important;
	border: 2px solid #f3eded;
	max-width: 75%;
	font-family: inherit;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #555;
	background-color: #FCFCFC
}

.search-form button[type=submit] {
	padding: 9px 12px 9px;
	margin-top: 0
}

input[type=submit] {
	background-color: #2c2e3d;
	border: 0;
	padding: 7px 35px;
	font-weight: 400;
	color: #FFF
}

#respond input[type=submit], button[type=submit], input[type=button] {
	background-color: #333;
	padding: 5px 15px;
	border: none;
	margin-top: 0;
	color: #fff;
	cursor: pointer;
	transition: all .2s linear 0s;
	-moz-transition: all .2s linear 0s;
	-webkit-transition: all .2s linear 0s;
	-o-transition: all .2s linear 0s;
	width: auto;
	font-size: 16px;
	font-weight: 400;
	border-radius: 2px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	-o-border-radius: 2px
}

.home-ftext {
	height: 33px
}

.wpcf7-form p {
	margin: 0 0 8px !important
}

.wpcf7-submit {
	font-size: 16px !important
}

.woocommerce-form {
	font-size: 16px
}

.woocommerce-MyAccount-navigation ul li a {
	font-size: 16px
}
